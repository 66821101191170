import React, { useEffect } from 'react'
import image from '../assets/illustrations/calling_back.svg'
import Layout from '../components/layout'
import SEO from '../components/seo'

const isBrowser = typeof window !== 'undefined'

const OrderFulfilled = () => {
  const paymentIntentId = isBrowser ? new URLSearchParams(window.location.search).get('payment_intent') : null

  useEffect(() => {
    if (!paymentIntentId) return

    async function fetchData() {
      const { status } = await fetch('/.netlify/functions/retrieve-charge', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ paymentIntentId }),
      })
      return status
    }

    fetchData()
  }, [])

  return (
    <Layout>
      <SEO title="Order fulfilled" />
      <section className="bg-alternative-lilas py-8 mt-6">
        <h2 className="font-header text-3xl text-center">Merci pour votre achat !</h2>
        <p className="font-header text-xl text-center mt-3">
          Je vais prendre contact avec vous pour la suite de la prestation.
        </p>
      </section>
      <img src={image} alt="image de remerciement" className="mt-12 w-1/2 mx-auto" />
    </Layout>
  )
}

export default OrderFulfilled
